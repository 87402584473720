const Works = () => {
    return (
      <div className="works-contents">
        <div className="container" style={{"background-image": 'url("./images/works/icon-3.png")'}}>
          <a href="https://github.com/srrrkn/Splitch">
            <div className="description" >
              <div className="title">Splitch</div>
              <p>
              誰でも簡単に上手なスケッチが書けるようになるiOSアプリです。
              一枚の画像を分割して表示します。一部分ずつ丁寧に書いていくと誰でも簡単に上手なスケッチができます。
              </p>
              <div className="language">開発言語: C#.NET</div>
              <div className="latest-released-at">作成時期: 2020-04-17</div>
            </div>
          </a>
        </div>
        <div className="container" style={{"background-image": 'url("./images/works/icon-1.png")'}}>
          <a href="https://github.com/srrrkn/ScShoAlpha">
            <div className="description" >
              <div className="title">ScShoPlus</div>
              <p>
              WindowsのPrintScreenによる画面キャプチャ機能を強化するツールです。
              撮影したその場でのトリミングやデスクトップへの保存が可能になります。
              VBで初めて開発したツールをC#に置き換えました。
              </p>
              <div className="language">開発言語: C#.NET</div>
              <div className="latest-released-at">作成時期: 2020-04-17</div>
            </div>
          </a>
        </div>
      
        <div className="container" style={{"background-image": 'url("./images/works/icon-2.png")'}}>
          <a href="https://github.com/srrrkn/e-simulator">
            <div className="description" >
              <div className="title">e-simulator</div>
              <p>
              学生時代の友人の研究を手伝った際のプログラムです。
              MASによる選挙のシミュレーションの可視化をしています。
              Artisocで実行していたシミュレーションをJavaに置き換えたものです。
              </p>
              <div className="language">開発言語: Java</div>
              <div className="latest-released-at">作成時期: 2017-02-14</div>
            </div>
          </a>
        </div>
      </div>
    );
};
export default Works;